import { render, staticRenderFns } from "./SearchProjectPage.vue?vue&type=template&id=37623e28&scoped=true"
import script from "./SearchProjectPage.vue?vue&type=script&lang=js"
export * from "./SearchProjectPage.vue?vue&type=script&lang=js"
import style0 from "./SearchProjectPage.vue?vue&type=style&index=0&id=37623e28&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37623e28",
  null
  
)

export default component.exports